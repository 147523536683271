<template>
  <div
    class="zy-gant-plus-row"
    :style="{ background: config.color, left: `${startDateDay * plus.config.dayWidth}px`, height: config.height, width: `${lengthDay * plus.config.dayWidth}px` }"
  ></div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      //一天总毫秒数
      dayMill: 24 * 60 * 60 * 1000,
    }
  },
  props: {
    plus: Object,
    config: {
      type: Object,
      default: () => {
        return {}
      },
    },
    row: Object,
  },
  computed: {
    // 计划开始日期
    startDate() {
      return this.row[this.config.start]
    },
    //计划完成日期
    endDate() {
      return this.row[this.config.end]
    },
    //时长(天数*一天的宽度=条形宽度)
    lengthDay() {
      if (!this.startDate || !this.startDate.length) return 0
      let endDate = this.endDate
      if (!endDate || !endDate.length) {
        endDate = moment(new Date()).format("YYYY-MM-DD")
      }
      const start = new Date(this.startDate).getTime()
      const end = new Date(endDate).getTime()
      const number = Math.round(Math.abs((end - start) / this.dayMill) + 1)
      return number
    },
    //计划开始的天数（条形左边开始位置，天数*一天的长度）
    startDateDay() {
      //获取计划开始的时间戳
      const start = new Date(this.startDate).getTime()
      let length = 0 // 记录开始距离左边的天数
      this.plus.headList.forEach((month) => {
        month.days.forEach((dayRand) => {
          dayRand.des.forEach((dayTime) => {
            //对比两个时间戳的大小，如果小如开始时间，则当天计划还没开始。
            if (dayTime < start) {
              length++
            }
          })
        })
      })
      return length + 1
    },
  },
}
</script>

<style lang="less" scoped>
.zy-gant-plus-row {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 11px;
  height: 40px;
}
</style>
